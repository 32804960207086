import React from 'react';

export interface IContentProps {
  children: React.ReactNode;
}

export const Content: React.FC<IContentProps> = ({ children }) => {
  return (
    <div className="relative min-h-screen px-8 mt-6 mb-24 xl:px-14 min-w-[820px]">
      {children}
    </div>
  );
};

export default Content;
