import React from 'react';

import clsx from 'clsx';

import { Label } from '@/components/atoms';

export interface IFilterFieldProps {
  className?: string;
  children: React.ReactNode;
  title: string;
  width?: number;
}

const BASE_CLASSES = 'mr-3';

export const FilterField: React.FC<IFilterFieldProps> = ({
  children,
  className,
  title,
  width = 150,
}) => {
  return (
    <div className={clsx(BASE_CLASSES, className)} style={{ width }}>
      <Label className="text-[14px]" htmlFor={title}>
        {title}
      </Label>
      <div>{children}</div>
    </div>
  );
};

export default FilterField;
