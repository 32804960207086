import React from 'react';

import RcTable from 'rc-table';
import { TableProps } from 'rc-table/lib/Table';

export interface ITableProps extends TableProps<Record<string, any>> {}

export const Table: React.FC<ITableProps> = ({ ...rest }) => {
  return <RcTable className="custom-table" {...rest} />;
};

export default Table;
