import React from 'react';

import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { SidebarNavigation } from '@/components/atoms';
import { Modal, useModal } from '@/components/molecules/Modal';
import { useGlobalState } from '@/contexts/GlobalStateContext';

import ChangeIcon from '../../../svgs/icons/change.svg';
import PartnersManagementIcon from '../../../svgs/icons/partners-management.svg';
import RechargeCodesIcon from '../../../svgs/icons/recharge-codes.svg';
import ReportsIcon from '../../../svgs/icons/reports.svg';
import SettingsIcon from '../../../svgs/icons/settings.svg';
import TickIcon from '../../../svgs/icons/tick.svg';
import TransactionsIcon from '../../../svgs/icons/transactions.svg';
import Logo from '../../../svgs/logo.svg';

export interface ISidebarProps {}

export const Sidebar: React.FC<ISidebarProps> = () => {
  const { globalState } = useGlobalState();
  const router = useRouter();
  const { isOpened, hide, toggle } = useModal({});

  const go = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/#/home`;
  };

  return (
    <>
      <aside className="sticky inset-y-0 top-0 left-0 w-64 h-screen overflow-y-auto text-white transform bg-blue-900">
        <div>
          <NextLink
            className="flex items-center justify-center h-full my-7"
            href="/"
          >
            <Logo className="w-36" />
          </NextLink>
        </div>
        <div>
          <button
            className="flex items-center justify-between w-full px-[25px] py-[12px] text-left bg-green-500 focus:outline-none"
            onClick={toggle}
          >
            Universal Points Terminal
            <ChangeIcon className="w-3" />
          </button>
        </div>
        <div>
          <SidebarNavigation
            activeItemId={router.pathname}
            items={[
              {
                title: 'Partners Management',
                itemId: '/partners-management',
                elemBefore: () => <PartnersManagementIcon className="w-6" />,
                subNav: [
                  {
                    title: 'Partners',
                    itemId: '/partners-management/partners',
                  },
                  {
                    title: 'Stores',
                    itemId: '/partners-management/stores',
                  },
                  {
                    title: 'Users',
                    itemId: '/partners-management/users',
                  },
                ],
              },
              {
                title: 'Transactions',
                itemId: '/transactions',
                elemBefore: () => <TransactionsIcon className="w-6" />,
              },
              {
                title: 'Reports',
                itemId: '/reports',
                elemBefore: () => <ReportsIcon className="w-6" />,
              },
              {
                title: 'Recharge Codes',
                itemId: '/recharge-codes',
                elemBefore: () => <RechargeCodesIcon className="w-6" />,
              },
              {
                title: 'UPT Settings',
                itemId: '/settings',
                elemBefore: () => <SettingsIcon className="w-5 mr-1" />,
                showIf: globalState.currencyOwner.role !== 'basic',
              },
            ]}
          />
        </div>
      </aside>
      <Modal bgColor="#2C3C54" open={isOpened} placement="left" onClose={hide}>
        <div className="text-white">
          <p className="px-4 py-6 text-sm">Select the solution you want</p>
          <div>
            <button className="flex items-center justify-between w-full p-4 mb-0.5 text-left bg-green-500 focus:outline-none">
              Universal Points Terminal
              <TickIcon className="w-4" />
            </button>
            <button
              className="flex items-center justify-between w-full p-4 text-left bg-green-500 focus:outline-none"
              onClick={go}
            >
              Management Portal
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Sidebar;
