import React, { useEffect } from 'react';

import clsx from 'clsx';

import ClientOnlyPortal from '@/hoc/ClientOnlyPortal';
import useToggle from '@/hooks/useToggle';

import CrossIcon from '../../../svgs/icons/cross.svg';

export interface IModalProps {
  bgColor?: string;
  className?: string;
  closeBtn?: boolean;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  placement?: 'right' | 'left' | 'center';
  width?: number;
}

export const Modal: React.FC<IModalProps> = ({
  bgColor = 'white',
  className,
  closeBtn = false,
  children,
  open,
  onClose,
  placement = 'center',
  width,
}) => {
  function escHandler({ key }: { key: string }) {
    if (key === 'Escape') {
      onClose();
    }
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', escHandler);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', escHandler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClientOnlyPortal selector="#modal">
      <div
        className={clsx('fixed inset-0 z-50', {
          'pointer-events-none': !open,
        })}
      >
        <div
          className={clsx(
            'fixed inset-0 bg-black transition-opacity duration-300 ease-in-out',
            {
              'bg-opacity-40': open,
              'pointer-events-none bg-opacity-0': !open,
            },
          )}
          onClick={onClose}
        />

        <div
          className={clsx(
            'fixed shadow-lg transition-[opacity,translate] duration-300 ease-in-out overflow-y-auto',
            {
              'opacity-100': open,
              'pointer-events-none opacity-0': !open,
              'right-0 h-full w-64 max-w-screen-sm': placement === 'right',
              '-translate-x-0': open && placement === 'right',
              'translate-x-full': !open && placement === 'right',
              'left-0 h-full w-64 max-w-screen-sm': placement === 'left',
              'translate-x-0': open && placement === 'left',
              '-translate-x-full': !open && placement === 'left',
              'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-screen-sm':
                placement === 'center',
            },
            className,
          )}
          style={{ backgroundColor: bgColor, width }}
        >
          {closeBtn && (
            <div
              className="absolute cursor-pointer top-4 right-4"
              onClick={onClose}
            >
              <CrossIcon className="w-5 text-gray-700" />
            </div>
          )}
          <div>{children}</div>
        </div>
      </div>
    </ClientOnlyPortal>
  );
};

export const useModal = ({ isShown }: { isShown?: boolean }) => {
  const { value, toggle, setFalse, setTrue } = useToggle(isShown ?? false);

  return { isOpened: value, hide: setFalse, show: setTrue, toggle };
};
