import { useRef, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

interface IClientOnlyPortalProps {
  children: React.ReactNode;
  selector: string;
}

const ClientOnlyPortal: React.FC<IClientOnlyPortalProps> = ({
  children,
  selector,
}) => {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // @ts-ignore
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  // @ts-ignore
  return mounted ? createPortal(children, ref.current) : null;
};

export default ClientOnlyPortal;
