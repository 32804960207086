import React from 'react';

export interface IMainProps {
  children: React.ReactNode;
}

export const Main: React.FC<IMainProps> = ({ children }) => {
  return (
    <main className="relative flex-1 min-h-screen overflow-y-auto bg-gray-200">
      {children}
    </main>
  );
};

export default Main;
