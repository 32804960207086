import React from 'react';

import clsx from 'clsx';

import { Label } from '@/components/atoms';

export interface IFormRowProps {
  children: React.ReactNode;
  containerClasses?: string;
  itemsCentered?: boolean;
  label: string | React.ReactNode;
  leftCol?: string;
  name: string;
  required?: boolean;
  rightCol?: string;
  subLabel?: string | React.ReactNode;
}

export const FormRow: React.FC<IFormRowProps> = ({
  children,
  containerClasses,
  itemsCentered = false,
  label,
  leftCol,
  name,
  required = false,
  rightCol,
  subLabel,
}) => {
  return (
    <div className={clsx('flex my-4', containerClasses)}>
      <div
        className={clsx('pr-3', {
          'w-2/6 xl:w-2/6 2xl:w-3/12': !leftCol,
          // @ts-ignore
          [leftCol]: leftCol,
        })}
      >
        <Label
          className="h-[34px] flex items-center"
          htmlFor={name}
          required={required}
        >
          {label}
        </Label>
        {subLabel && typeof subLabel === 'string' && (
          <p className="text-[#9B9B9B] text-sm">{subLabel}</p>
        )}
        {React.isValidElement(subLabel) && subLabel}
      </div>
      <div
        className={clsx('text-[15px] text-blue-850', {
          'flex items-center': itemsCentered,
          'w-4/6 xl:w-4/6 2xl:w-9/12': !rightCol,
          // @ts-ignore
          [rightCol]: rightCol,
        })}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default FormRow;
