import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import { Config } from '@/utils/Config';

export interface IMetaProps {
  title?: string;
  description?: string;
  canonical?: string;
}

const Meta: React.FC<IMetaProps> = (props: IMetaProps) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <link
          key="apple"
          href={`${router.basePath}/apple-touch-icon.png`}
          rel="apple-touch-icon"
        />
        <link
          key="icon32"
          href={`${router.basePath}/favicon-32x32.png`}
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          key="icon16"
          href={`${router.basePath}/favicon-16x16.png`}
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          key="favicon"
          href={`${router.basePath}/favicon.ico`}
          rel="icon"
        />
        <link href={`${router.basePath}/site.webmanifest`} rel="manifest" />
      </Head>
      <NextSeo
        canonical={props.canonical}
        description={props.description ?? undefined}
        openGraph={{
          title: props.title ?? Config.site_name,
          description: props.description ?? undefined,
          url: props.canonical,
          locale: Config.locale,
          site_name: Config.site_name,
        }}
        title={props.title}
      />
    </>
  );
};

export { Meta };
