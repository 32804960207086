import React from 'react';

import { Button } from '@/components/atoms';

export interface INoItemsProps {
  infoText: string;
  btnText?: string;
  btnUrl?: string;
}

export const NoItems: React.FC<INoItemsProps> = ({
  infoText,
  btnText,
  btnUrl,
}) => {
  return (
    <div className="text-center text-blue-900 bg-white py-14">
      <p className="mb-6">{infoText}</p>
      {btnText && btnUrl && (
        <Button as="link" href={btnUrl}>
          {btnText}
        </Button>
      )}
    </div>
  );
};

export default NoItems;
