import React from 'react';

export interface IHeaderProps {
  children: React.ReactNode;
}

export const Header: React.FC<IHeaderProps> = ({ children }) => {
  return (
    <header className="flex items-center justify-between h-[70px] bg-white px-8 xl:px-14 border-b border-zinc-200">
      {children}
    </header>
  );
};

export default Header;
